import { Helmet } from 'react-helmet';

function Privacy() {
  const title = 'Privacy Policy';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container my-3">
        <div
          className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div className="col p-4 d-flex flex-column position-static">
            <h1>Terms of Service</h1>
          </div>
          <div className="col-auto d-none d-lg-block">
            <div className="pt-3 pe-3">
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Privacy;
