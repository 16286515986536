import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Sell from '../images/sell.png';

function Home() {
  const title = 'Flowbot';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-fluid hero-banner px-0">
        <div className="row py-5 mx-auto">
          <div className="col-lg-6 hero-banner-content">
            <p className="lead mb-4">
            <h1><span>Intelligence</span><br/>driving end to end product success</h1>
            <h3>Inform decisions from <br/>real time data analytics and models <br/>trained on your live real world data and <br/>finessed with generative ai</h3>
            </p>
          </div>
          <div className="col-lg-6">
            <img src={Sell} alt="Sell me"></img>
          </div>
        </div>
        <div className="col-lg-12 splitter"></div>
      </main>
    </>
  );
}

export default Home;
